import React from "react";
import { Link } from "react-router-dom";
import style from "./Footer.module.scss";

const Footer = () => {
  const date = new Date();
  return (
    <div className={style.footerWrapper}>
      <div className={style.footer}>
        <p>
          <Link to="/privacy" className={style.privacy}>
            Политика в отношении обработки персональных данных
          </Link>
        </p>
        <p className={style.copyright}>
          МП «Нижегородское метро» {date.getFullYear()} © Разработка:{" "}
          <a href="https://mithra.ru">Mithra</a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
